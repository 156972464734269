import { AppContext } from "../../../../AppContext";
import { externalUserHanselClasses } from "../../utils/externalUserHanselClasses";
import { handleInitialPrices, handleOrgExternalBusinessId } from "../utils";
import { ReservationContext } from "../../contextProviders/ReservationContext";
import { reservationDataAtom } from "../../../../atoms/reservationDataAtom";
import { reservationFields } from "../fields";
import { useAtomValue } from "jotai";
import { useCategories } from "../../../../queries/useCategories";
import { useContext } from "react";
export const useGetInitialFormDataExternalUsers = () => {
  const reservationData = useAtomValue(reservationDataAtom);
  const { business, user, company, timezone } = useContext(AppContext);
  const { insuranceCompanies } = useContext(ReservationContext);

  const categories = useCategories();
  const orgBusinessId = business?.id ?? undefined;
  const INITIAL_FORM_DATA = {
    [reservationFields.id]: reservationData?.id ?? undefined,
    [reservationFields.group]: reservationData?.group ?? undefined,
    [reservationFields.organizationId]:
      reservationData?.organizationId ?? user?.organizationId,
    [reservationFields.orgBusinessId]:
      reservationData?.orgBusinessId ?? orgBusinessId,
    [reservationFields.orgExternalBusinessId]: handleOrgExternalBusinessId(
      reservationData,
      user
    ),
    [reservationFields.companyName]: reservationData?.companyName ?? undefined,
    [reservationFields.companyBusinessId]:
      reservationData?.companyBusinessId ?? undefined,
    [reservationFields.companyCity]: reservationData?.companyCity ?? undefined,
    [reservationFields.companyPostalCode]:
      reservationData?.companyPostalCode ?? undefined,
    [reservationFields.companyAddress]:
      reservationData?.companyAddress ?? undefined,
    [reservationFields.companyBillingRef]:
      reservationData?.companyBillingRef ?? undefined,
    [reservationFields.companyBillingAddress]:
      reservationData?.companyBillingAddress ?? undefined,
    [reservationFields.customerCompanyId]:
      reservationData?.customerCompanyId ?? undefined,
    [reservationFields.companyId]: reservationData?.companyId ?? company?.id,
    [reservationFields.isBroker]: Boolean(reservationData?.customerBrokerId),
    [reservationFields.customerBrokerId]:
      reservationData?.customerBrokerId ?? undefined,
    [reservationFields.startLocation]:
      reservationData?.startLocation ?? company?.name,
    [reservationFields.startLocationID]:
      reservationData?.startLocationID ?? company?.id,
    [reservationFields.returnLocation]:
      reservationData?.returnLocation ?? company?.name,
    [reservationFields.returnLocationID]:
      reservationData?.returnLocationID ?? company?.id,
    [reservationFields.startTime]: reservationData?.startTime
      ? new Date(reservationData?.startTime)
      : getStartTime(reservationData, company?.externalBusinessId, timezone),
    [reservationFields.returnTime]: reservationData?.returnTime
      ? new Date(reservationData?.returnTime)
      : getReturnTime(reservationData, company?.externalBusinessId),
    [reservationFields.endTime]: reservationData?.endTime
      ? new Date(reservationData?.endTime)
      : getEndTime(reservationData, company?.externalBusinessId),
    [reservationFields.name]: reservationData?.name ?? user?.name,
    [reservationFields.address]: reservationData?.address ?? undefined,
    [reservationFields.postalCode]: reservationData?.postalCode ?? undefined,
    [reservationFields.city]: reservationData?.city ?? undefined,
    [reservationFields.phone]: reservationData?.phone ?? undefined,
    [reservationFields.email]: reservationData?.email ?? user?.email,
    [reservationFields.ssn]: reservationData?.ssn ?? undefined,
    [reservationFields.reservationVehicleId]:
      reservationData?.reservationVehicleId ?? undefined,
    [reservationFields.reservationVehicles]:
      reservationData?.reservationVehicles ?? [],
    [reservationFields.wildCardVehicles]:
      reservationData?.wildCardVehicles ?? [],
    [reservationFields.vehiclePrice]:
      reservationData?.vehiclePrice ?? undefined,
    [reservationFields.additionalServices]: handleInitialPrices(
      reservationData?.additionalServices
    ),
    [reservationFields.rentalNotes]: reservationData?.rentalNotes ?? undefined,
    [reservationFields.internalNotes]:
      reservationData?.internalNotes ?? undefined,
    [reservationFields.externalNotes]:
      reservationData?.externalNotes ?? undefined,
    [reservationFields.outOfCommission]:
      reservationData?.outOfCommission ?? undefined,
    [reservationFields.driverSSN]: reservationData?.driverSSN ?? undefined,
    [reservationFields.differentDriver]: Boolean(
      reservationData?.differentDriver
    ),
    [reservationFields.driverName]: reservationData?.driverName ?? undefined,
    [reservationFields.driverAddress]:
      reservationData?.driverAddress ?? undefined,
    [reservationFields.driverPostalCode]:
      reservationData?.driverPostalCode ?? undefined,
    [reservationFields.driverCity]: reservationData?.driverCity ?? undefined,
    [reservationFields.driverPhone]: reservationData?.driverPhone ?? undefined,
    [reservationFields.driverEmail]: reservationData?.driverEmail ?? undefined,
    [reservationFields.additionalDriver]: Boolean(
      reservationData?.additionalDriver
    ),
    [reservationFields.additionalDriverName]:
      reservationData?.additionalDriverName ?? undefined,
    [reservationFields.additionalDriverPhone]:
      reservationData?.additionalDriverPhone ?? undefined,
    [reservationFields.additionalDriverSSN]:
      reservationData?.additionalDriverSSN ?? undefined,
    [reservationFields.billPayment]: reservationData?.billPayment ?? undefined,
    [reservationFields.isCompany]: true,
    [reservationFields.enableRegistrationPlate]: Boolean(
      reservationData?.enableRegistrationPlate
    ),
    [reservationFields.disablePricesInPrintouts]: Boolean(
      reservationData?.disablePricesInPrintouts
    ),
    [reservationFields.customerCompanyId]:
      reservationData?.customerCompanyId ?? undefined,
    [reservationFields.companyName]:
      reservationData?.companyName ?? user?.externalBusiness?.name,
    [reservationFields.companyBusinessId]:
      reservationData?.companyBusinessId ?? undefined,
    [reservationFields.companyCity]: reservationData?.companyCity ?? undefined,
    [reservationFields.companyPostalCode]:
      reservationData?.companyPostalCode ?? undefined,
    [reservationFields.companyBillingRef]:
      reservationData?.companyBillingRef ?? undefined,
    [reservationFields.companyBillingAddress]:
      reservationData?.companyBillingAddress ?? undefined,
    [reservationFields.companyEBillingOperator]:
      reservationData?.companyEBillingOperator ?? undefined,
    [reservationFields.companyEBillingAddress]:
      reservationData?.companyEBillingAddress ?? undefined,
    [reservationFields.paymentStatus]:
      reservationData?.paymentStatus ?? undefined,
    [reservationFields.paymentData]: reservationData?.paymentData ?? undefined,
    [reservationFields.authStatus]: reservationData?.authStatus ?? undefined,
    [reservationFields.mustSign]: Boolean(reservationData?.mustSign),
    [reservationFields.completeBefore]:
      reservationData?.completeBefore ?? undefined,
    [reservationFields.rentalStatus]:
      reservationData?.rentalStatus ?? undefined,
    [reservationFields.innovoiceStatus]:
      reservationData?.innovoiceStatus ?? undefined,
    [reservationFields.insuranceData]: handleInsuranceData(reservationData),
    [reservationFields.hanselData]: reservationData?.hanselData ?? undefined,
    [reservationFields.customerCarRegistrationPlate]:
      reservationData?.customerCarRegistrationPlate ?? undefined,
    [reservationFields.channel]: reservationData?.channel ?? undefined,
    [reservationFields.type]: reservationData?.type ?? undefined,
    [reservationFields.status]: reservationData?.status ?? undefined,
    [reservationFields.reservationStatusInfo]:
      reservationData?.reservationStatusInfo ?? undefined,
    [reservationFields.initialCategory]:
      reservationData?.initialCategory ?? undefined,
    [reservationFields.initialClassInfo]:
      reservationData?.initialClassInfo ?? undefined,
    [reservationFields.pricingCategoryId]: handlePricingCategoryId(
      reservationData,
      categories
    ),
    insuranceCompany: handleInsuranceCompany(
      reservationData,
      insuranceCompanies
    ),
  };
  return INITIAL_FORM_DATA;
};

const getStartTime = (reservationData, externalBusinessId, timezone) => {
  if (externalBusinessId) return null;
  if (reservationData?.startTime) {
    return new Date(reservationData?.startTime);
  } else {
    return null;
  }
};
const getReturnTime = (reservationData, externalBusinessId) => {
  if (externalBusinessId) return null;
  if (reservationData?.returnTime) {
    return new Date(reservationData?.returnTime);
  } else {
    return null;
  }
};
const getEndTime = (reservationData, externalBusinessId) => {
  if (externalBusinessId) return null;
  if (reservationData?.endTime) {
    return new Date(reservationData?.endTime);
  } else {
    return null;
  }
};

const handleInsuranceCompany = (reservationData, insuranceCompanies) => {
  // Initialize insuranceCompany field
  if (reservationData?.insuranceData?.providerBusinessId) {
    const getProvider =
      insuranceCompanies?.find(
        (insuranceCompany) =>
          insuranceCompany.businessId ===
          reservationData?.insuranceData?.providerBusinessId
      ) ?? null;
    return getProvider;
  }
};

const handleInsuranceData = (reservationData) => {
  if (reservationData?.type === "INSURANCE") {
    return {
      customerContact: reservationData?.insuranceData?.customerContact ?? false, // Kontaktoitu
      urgent: reservationData?.insuranceData?.urgent ?? false, // Kiireellinen
      customerCarModel: reservationData?.insuranceData?.customerCarModel ?? "", //Asiakkaan auton malli
      customerCarMaker: reservationData?.insuranceData?.customerCarMaker ?? "", // Asiakkaan auton valmistaja
      product: reservationData?.insuranceData?.product ?? "", // Vakuutustuote
      coveragePercentage:
        reservationData?.insuranceData?.coveragePercentage ?? 0, // Korvausaste
      compensatableDays:
        reservationData?.insuranceData?.compensatableDays ?? "", // Enimmäiskorvausaika
      billingPermitCode:
        reservationData?.insuranceData?.billingPermitCode ?? "", // Laskutuslupatunnus
      coverageDuration: reservationData?.insuranceData?.coverageDuration ?? 0, // Vakuutuksen voimassaoloaika
      providerCompany: reservationData?.insuranceData?.providerCompany ?? "", // Vakuutusyhtiö (maksaja)
      providerBusinessId:
        reservationData?.insuranceData?.providerBusinessId ?? "", // Vakuutusyhtiö (maksaja)
      claimAdjusterEmail: reservationData?.insuranceData?.claimAdjusterEmail, // Korvauskäsittelijän sähköposti
      claimAdjusterName: reservationData?.insuranceData?.claimAdjusterName, // Korvauskäsittelijän nimi
      receipt: reservationData?.insuranceData?.receipt ?? false, // Haluan kuittauksen
      replacementVehicleClass:
        reservationData?.insuranceData?.replacementVehicleClass ?? "", // Korvattava autoluokka
      claimId: reservationData?.insuranceData?.claimId ?? "", // Vahinkonumero
      vatDeductible: reservationData?.insuranceData?.vatDeductible ?? false, // ALV -vähennettävä
    };
  }
};

const handlePricingCategoryId = (reservationData, categories) => {
  if (reservationData?.pricingCategoryId) {
    return reservationData?.pricingCategoryId;
  } else {
    if (reservationData?.insuranceData) {
      const excludedCategories = [
        "AVOKÄR1",
        "AVOKÄR2",
        "Alumiiniramppi",
        "AAA - tarkista autoluokka",
      ];
      const suggestedCategory = categories.find(
        (c) =>
          c.name.startsWith(
            reservationData?.insuranceData?.replacementVehicleClass
          ) && !excludedCategories.includes(c?.name)
      );
      return suggestedCategory?.id;
    }

    if (reservationData?.hanselData) {
      return externalUserHanselClasses.find(
        (hanselClass) =>
          hanselClass.class === reservationData?.initialClassInfo?.hanselClass
      )?.contractVehicleClass;
    }
  }
};
