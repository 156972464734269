import { useSetAtom } from "jotai";
import { reservationDataAtom } from "../../../../../atoms/reservationDataAtom";
import { Box, Button } from "@mui/material";

export function DialogFooter() {
  const setEditReservationData = useSetAtom(reservationDataAtom);

  return (
    <Box
      display={"flex"}
      justifyContent={"flex-end"}
      position={"sticky"}
      bottom={0}
      bgcolor={"background.paper"}
    >
      <Button onClick={() => setEditReservationData(null)}>Sulje</Button>
    </Box>
  );
}
