export const reservationFields = {
  id: "id",
  group: "group",
  organizationId: "organizationId",
  orgBusinessId: "orgBusinessId",
  orgExternalBusinessId: "orgExternalBusinessId",
  companyId: "companyId",
  customerBrokerId: "customerBrokerId",
  startLocation: "startLocation",
  startLocationID: "startLocationID",
  returnLocation: "returnLocation",
  returnLocationID: "returnLocationID",
  startTime: "startTime",
  returnTime: "returnTime",
  endTime: "endTime",
  name: "name",
  address: "address",
  postalCode: "postalCode",
  city: "city",
  phone: "phone",
  email: "email",
  ssn: "ssn",
  reservationVehicleId: "reservationVehicleId",
  reservationVehicles: "reservationVehicles",
  wildCardVehicles: "wildCardVehicles",
  vehiclePrice: "vehiclePrice",
  additionalServices: "additionalServices",
  rentalNotes: "rentalNotes",
  internalNotes: "internalNotes",
  externalNotes: "externalNotes",
  outOfCommission: "outOfCommission",
  driverSSN: "driverSSN",
  differentDriver: "differentDriver",
  driverName: "driverName",
  driverAddress: "driverAddress",
  driverPostalCode: "driverPostalCode",
  driverCity: "driverCity",
  driverPhone: "driverPhone",
  driverEmail: "driverEmail",
  additionalDriver: "additionalDriver",
  additionalDriverName: "additionalDriverName",
  additionalDriverPhone: "additionalDriverPhone",
  additionalDriverSSN: "additionalDriverSSN",
  billPayment: "billPayment",
  isCompany: "isCompany",
  enableRegistrationPlate: "enableRegistrationPlate",
  disablePricesInPrintouts: "disablePricesInPrintouts",
  customerCompanyId: "customerCompanyId",
  companyName: "companyName",
  companyCity: "companyCity",
  companyPostalCode: "companyPostalCode",
  companyBusinessId: "companyBusinessId",
  companyBillingRef: "companyBillingRef",
  companyBillingAddress: "companyBillingAddress",
  companyEBillingOperator: "companyEBillingOperator",
  companyEBillingAddress: "companyEBillingAddress",
  paymentStatus: "paymentStatus",
  paymentMethod: "paymentMethod",
  paymentData: "paymentData",
  authStatus: "authStatus",
  mustSign: "mustSign",
  completeBefore: "completeBefore",
  rentalStatus: "rentalStatus",
  innovoiceStatus: "innovoiceStatus",
  insuranceData: "insuranceData",
  isBroker: "isBroker",
  hanselData: "hanselData",
  customerCarRegistrationPlate: "customerCarRegistrationPlate",
  channel: "channel",
  type: "type",
  status: "status",
  reservationStatusInfo: "reservationStatusInfo",
  initialCategory: "initialCategory",
  initialClassInfo: "initialClassInfo",
  pricingCategoryId: "pricingCategoryId",
  mandatoryProduct: "mandatoryProduct",
};

const translatedFieldsENG = {
  id: "Reservation number",
  group: "Group",
  organizationId: "Organization",
  orgBusinessId: "Organization business ID",
  orgExternalBusinessId: "Organization external business ID",
  customerBrokerId: "Select agent",
  companyId: "Company",
  startLocation: "Pickup location",
  startLocationID: "Pickup location ID",
  returnLocation: "Return location",
  returnLocationID: "Return location ID",
  returnBeforeStart: "Return before pickup",
  startTime: "Pickup time",
  returnTime: "Return time",
  endTime: "End time",
  name: "Name",
  address: "Address",
  postalCode: "Postal code",
  city: "City",
  phone: "Phone",
  email: "Email",
  ssn: "Social security number",
  reservationVehicleId: "Reservation vehicle ID",
  reservationVehicles: "Reservation vehicles",
  wildCardVehicles: "Leisure vehicles",
  vehiclePrice: "Vehicle price",
  additionalServices: "Additional services",
  rentalNotes: "Rental notes",
  internalNotes: "Messages",
  externalNotes: "Customer message",
  outOfCommission: "Out of commission",
  driverSSN: "Driver's social security number",
  differentDriver: "Different driver",
  driverName: "Driver's name",
  driverAddress: "Driver's address",
  driverPostalCode: "Driver's postal code",
  driverCity: "Driver's city",
  driverPhone: "Driver's phone",
  driverEmail: "Driver's email",
  additionalDriver: "Additional driver",
  additionalDriverName: "Additional driver's name",
  additionalDriverPhone: "Additional driver's phone",
  additionalDriverSSN: "Additional driver's social security number",
  billPayment: "Invoice payment",
  isCompany: "Company customer",
  enableRegistrationPlate: "Registration plate",
  disablePricesInPrintouts: "Remove prices from printouts",
  companyName: "Company name",
  companyBusinessId: "Company business ID",
  companyBillingRef: "Company billing reference",
  companyBillingAddress: "Company billing address",
  companyEBillingOperator: "Company e-billing operator",
  companyEBillingAddress: "Company e-billing address",
  paymentStatus: "Payment status",
  paymentData: "Payment details",
  authStatus: "Authentication",
  mustSign: "Must sign",
  completeBefore: "Complete before",
  rentalStatus: "Rental status",
  innovoiceStatus: "Invoice status",
  insuranceData: "Insurance details",
  hanselData: "Hansel data",
  customerCarRegistrationPlate: "Customer's car registration plate",
  channel: "Channel",
  type: "Type",
  status: "Status",
  reservationStatusInfo: "Reservation status",
  initialCategory: "Initial category",
  initialClassInfo: "Initial class",
  pricingCategoryId: "Vehicle pricing category",
  mandatoryProduct: "Products",
  totalPrice: "Total price for reservation",
  providerCompany: "Payer",
  reservationDetails: "Reservation details",
  selectCategory: "Select category",
  selectHanselClass: "Select Hansel class",
  verifyReservationTimes: "Verify reservation times",
  selectAdditionalServices: "Select additional services",
  fillContactInformation: "Fill in contact information",
  back: "Back",
  confirmReservation: "Confirm reservation",
  selectProvider: "Select company",
  customerInfo: "Customer information",
  companyInfo: "Company information",
};

const translatedFieldsSWE = {
  id: "Bokningsnummer",
  group: "Grupp",
  organizationId: "Organisation",
  orgBusinessId: "Organisationens organisationsnummer",
  orgExternalBusinessId: "Organisationens externa organisationsnummer",
  customerBrokerId: "Välj agent",
  companyId: "Företag",
  startLocation: "Hämtplats",
  startLocationID: "Hämtplats ID",
  returnLocation: "Återlämningsplats",
  returnLocationID: "Återlämningsplats ID",
  returnBeforeStart: "Återlämna innan hämtning",
  startTime: "Hämtningstid",
  returnTime: "Återlämningstid",
  endTime: "Sluttid",
  name: "Namn",
  address: "Adress",
  postalCode: "Postnummer",
  city: "Stad",
  phone: "Telefon",
  email: "E-post",
  ssn: "Personnummer",
  reservationVehicleId: "Bokningsfordons ID",
  reservationVehicles: "Bokningsfordon",
  wildCardVehicles: "Fritidsfordon",
  vehiclePrice: "Fordonspris",
  additionalServices: "Tilläggstjänster",
  rentalNotes: "Uthyrningsanteckningar",
  internalNotes: "Meddelanden",
  externalNotes: "Kundmeddelande",
  outOfCommission: "Utanför drift",
  driverSSN: "Förarens personnummer",
  differentDriver: "Annorlunda förare",
  driverName: "Förarens namn",
  driverAddress: "Förarens adress",
  driverPostalCode: "Förarens postnummer",
  driverCity: "Förarens stad",
  driverPhone: "Förarens telefon",
  driverEmail: "Förarens e-post",
  additionalDriver: "Extra förare",
  additionalDriverName: "Extra förarens namn",
  additionalDriverPhone: "Extra förarens telefon",
  additionalDriverSSN: "Extra förarens personnummer",
  billPayment: "Fakturabetalning",
  isCompany: "Företagskund",
  enableRegistrationPlate: "Registreringsskylt",
  disablePricesInPrintouts: "Priser borttagna från utskrifter",
  companyName: "Företagsnamn",
  companyBusinessId: "Företagets organisationsnummer",
  companyBillingRef: "Företagets faktureringsreferens",
  companyBillingAddress: "Företagets faktureringsadress",
  companyEBillingOperator: "Företagets e-faktureringsoperatör",
  companyEBillingAddress: "Företagets e-faktureringsadress",
  paymentStatus: "Betalningsstatus",
  paymentData: "Betalningsuppgifter",
  authStatus: "Autentisering",
  mustSign: "Måste signeras",
  completeBefore: "Slutför innan",
  rentalStatus: "Uthyrningsstatus",
  innovoiceStatus: "Fakturastatus",
  insuranceData: "Försäkringsuppgifter",
  hanselData: "Hansel-uppgifter",
  customerCarRegistrationPlate: "Kundens bilregistreringsskylt",
  channel: "Kanal",
  type: "Typ",
  status: "Status",
  reservationStatusInfo: "Bokningsstatus",
  initialCategory: "Ursprunglig kategori",
  initialClassInfo: "Ursprunglig klass",
  pricingCategoryId: "Priskategori för fordon",
  mandatoryProduct: "Produkter",
  totalPrice: "Totalpris för bokningen",
  providerCompany: "Betalare",
  reservationDetails: "Bokningsdetaljer",
  selectCategory: "Välj kategori",
  selectHanselClass: "Välj Hansel-klass",
  verifyReservationTimes: "Verifiera bokningstider",
  selectAdditionalServices: "Välj tilläggstjänster",
  fillContactInformation: "Fyll i kontaktinformation",
  back: "Tillbaka",
  confirmReservation: "Bekräfta bokning",
  selectProvider: "Välj företag",
  customerInfo: "Kundinformation",
  companyInfo: "Företagsinformation",
};

const translatedFieldsFIN = {
  id: "Varausnumero",
  group: "Ryhmä",
  organizationId: "Organisaatio",
  orgBusinessId: "Organisaation Y-tunnus",
  orgExternalBusinessId: "Organisaation ulkoinen Y-tunnus",
  customerBrokerId: "Valitse agentti",
  companyId: "Yritys",
  startLocation: "Noutopaikka",
  startLocationID: "Noutopaikan ID",
  returnLocation: "Palautuspaikka",
  returnLocationID: "Palautuspaikan ID",
  returnBeforeStart: "Palautus ennen noutoa",
  startTime: "Noutoaika",
  returnTime: "Palautusaika",
  endTime: "Lopetusaika",
  name: "Vuokraajan nimi",
  address: "Osoite",
  postalCode: "Postinumero",
  city: "Kaupunki",
  phone: "Puhelin",
  email: "Sähköposti",
  ssn: "Henkilötunnus",
  reservationVehicleId: "Varausajoneuvon ID",
  reservationVehicles: "Varausajoneuvot",
  wildCardVehicles: "Vapaa-ajan ajoneuvot",
  vehiclePrice: "Ajoneuvon hinta",
  additionalServices: "Lisäpalvelut",
  rentalNotes: "Vuokrauksen huomiot",
  internalNotes: "Viestit",
  externalNotes: "Asiakkaan viesti",
  outOfCommission: "Poissa käytöstä",
  driverSSN: "Kuljettajan henkilötunnus",
  differentDriver: "Eri kuljettaja",
  driverName: "Kuljettajan nimi",
  driverAddress: "Kuljettajan osoite",
  driverPostalCode: "Kuljettajan postinumero",
  driverCity: "Kuljettajan kaupunki",
  driverPhone: "Kuljettajan puhelin",
  driverEmail: "Kuljettajan sähköposti",
  additionalDriver: "Lisäkuljettaja",
  additionalDriverName: "Lisäkuljettajan nimi",
  additionalDriverPhone: "Lisäkuljettajan puhelin",
  additionalDriverSSN: "Lisäkuljettajan henkilötunnus",
  billPayment: "Laskutus",
  isCompany: "Yritysasiakas",
  enableRegistrationPlate: "Rekisterikilpi",
  disablePricesInPrintouts: "Hinnat pois tulosteista",
  companyName: "Yrityksen nimi",
  companyBusinessId: "Yrityksen Y-tunnus",
  companyBillingRef: "Yrityksen laskutusviite",
  companyBillingAddress: "Yrityksen laskutusosoite",
  companyEBillingOperator: "Yrityksen sähköisen laskutuksen operaattori",
  companyEBillingAddress: "Yrityksen sähköisen laskutuksen osoite",
  paymentStatus: "Maksun tila",
  paymentData: "Maksun tiedot",
  authStatus: "Autentikointi",
  mustSign: "Täytyy allekirjoittaa",
  completeBefore: "Täydennettävä ennen",
  rentalStatus: "Vuokrauksen tila",
  innovoiceStatus: "Laskun tila",
  insuranceData: "Vakuutustiedot",
  hanselData: "Hansel tiedot",
  customerCarRegistrationPlate: "Asiakkaan auton rekisterikilpi",
  channel: "Kanava",
  type: "Tyyppi",
  status: "Tila",
  reservationStatusInfo: "Varauksen tila",
  initialCategory: "Alkuperäinen kategoria",
  initialClassInfo: "Alkuperäinen luokka",
  pricingCategoryId: "Autoluokan hintaluokka",
  mandatoryProduct: "Tuotteet",
  totalPrice: "Varauksen summa",
  providerCompany: "Maksaja",
  reservationDetails: "Varauksen tiedot",
  selectCategory: "Valitse vuokrattava ajoneuvo",
  selectHanselClass: "Valitse Hansel-luokka",
  verifyReservationTimes: "Tarkista varauksen ajat",
  selectAdditionalServices: "Valitse lisäpalvelut",
  fillContactInformation: "Siirry täyttämään yhteystiedot",
  back: "Palaa muokkaamaan tietoja",
  confirmReservation: "Vahvista varaus",
  selectProvider: "Valitse yritys",
  customerInfo: "Asiakastiedot",
  companyInfo: "Yritystiedot",
};

export const translatedFields = {
  FIN: translatedFieldsFIN,
  SWE: translatedFieldsSWE,
  ENG: translatedFieldsENG,
};
