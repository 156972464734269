import { zonedTimeToUtc } from "date-fns-tz";
import { centsToLocalString, parseToCents } from "../../../shared/money";
import * as Sentry from "@sentry/react";
import { getReservationDuration } from "../utils/utilities";
import { reservationFields } from "./fields";

export const setTimeZones = (startTime, returnTime, endTime, company) => {
  const timezone = company?.timezone || "Europe/Helsinki";
  const startTimeUTC = zonedTimeToUtc(startTime, timezone);
  const returnTimeUTC = zonedTimeToUtc(returnTime, timezone);
  const endTimeUTC = zonedTimeToUtc(endTime || returnTime, timezone);

  return {
    startTimeUTC: startTimeUTC,
    returnTimeUTC: returnTimeUTC,
    endTimeUTC: endTimeUTC,
  };
};

export const handleOrgExternalBusinessId = (data, user) => {
  if (user?.externalBusinessId) {
    return user?.externalBusinessId;
  } else {
    return data?.orgExternalBusinessId ?? undefined;
  }
};

export const handleCompanyId = (
  companyId,
  reservationDataCompanyId,
  defaultCompanyId
) => {
  const fromForm = companyId;
  const fromReservation = reservationDataCompanyId ?? defaultCompanyId;
  return fromForm || fromReservation;
};

export const handleInsuranceData = (insuranceData, user) => {
  return {
    ...insuranceData,
    claimAdjusterEmail: insuranceData?.claimAdjusterEmail ?? user?.email,
    claimAdjusterName: insuranceData?.claimAdjusterName ?? user?.name,
  };
};

export const handleInitialPrices = (items) => {
  if (!items) return [];

  const parsePrices = items.map((item) => {
    const { listingPrice, ...rest } = item;
    return {
      ...rest,
      price:
        item?.price || item?.price === 0
          ? centsToLocalString(item?.price)
          : undefined,
      deductible: getDeductibleValue(item),
    };
  });

  return parsePrices;
};

const getDeductibleValue = (item) => {
  if (item?.deductible === 0) {
    return 0;
  } else {
    return item?.deductible ? centsToLocalString(item?.deductible) : undefined;
  }
};

const getServiceFinalPrice = (item) => {
  if (item.price) {
    // Manually set price
    return parseToCents(item.price);
  } else if (item.price === null) {
    // Manually emptied price field
    return 0;
  } else {
    // Price from offer
    return item?.offerPrice;
  }
};

export const handleServices = (services) => {
  if (!services) return [];
  return services.map((service) => {
    const {
      category,
      deductibleValue,
      description,
      details,
      enableNotification,
      key,
      quantity,
      tax,
    } = service;
    return {
      category,
      deductibleValue,
      description,
      details,
      enableNotification,
      key,
      quantity: String(quantity ?? 1),
      tax,
      price: getServiceFinalPrice(service),
    };
  });
};

export const handleReservationFailedSuccessfully = (
  code,
  emailConfirmationSuccess,
  email
) => {
  if (code !== null && code !== undefined) {
    Sentry.captureMessage("Reservation failed", {
      tags: {
        code: code,
      },
    });
    if (code === "VEHICLE_NOT_AVAILABLE") {
      return {
        message: "Ajoneuvo ei ole enää vapaana",
        variant: "warning",
        closeDialog: false,
      };
    } else {
      return {
        message: `Jokin meni vikaan varauksen tallentamisessa. Koodi: ${code}`,
        variant: "error",
        closeDialog: false,
      };
    }
  } else {
    if (!emailConfirmationSuccess) {
      Sentry.captureMessage("Send confirmation email failed", {
        tags: {
          code: "CONFIRMATION_EMAIL_FAILED",
          destinationEmail: email,
        },
      });

      return {
        message: "Sähköposti vahvistuksta ei voitu toimittaa asiakkaalle.",
        variant: "warning",
        closeDialog: true,
      };
    } else {
      return {
        message: "Varauksen tallennus onnistui",
        variant: "success",
        closeDialog: true,
      };
    }
  }
};

export const getCompleteBefore = (reservationLinkExpirationHours) => {
  const expirationHours = reservationLinkExpirationHours || 1;
  const expirationMs = expirationHours * 3600000;

  return new Date(new Date().getTime() + expirationMs);
};

export const getReservationStatus = (values) => {
  if (
    (values?.reservationVehicles?.length > 0 &&
      values?.status === "EXTERNAL_PENDING_DEALER") ||
    (values?.enableWildCard && values?.status === "EXTERNAL_PENDING_DEALER")
  ) {
    return "ALL_GOOD";
  } else {
    return values.status;
  }
};

export const getReservationPaymentData = (
  values,
  reservationData,
  totalPrice,
  company
) => {
  // Send link to user, through which the reservation can be
  // filled, paid and signed.
  const totalPriceCents = parseToCents(totalPrice);
  const paymentFields = {
    authStatus: undefined,
    completeBefore: undefined,
    paymentData: values?.paymentData ?? {},
  };

  if (values.sendStatusToCustomer) {
    paymentFields.completeBefore = getCompleteBefore(
      company?.reservationLinkExpirationHours
    );
    paymentFields.paymentData = {
      paymentTotal: totalPriceCents,
      paymentTodo: totalPriceCents,
    };
    if (company?.enableMustSign) {
      paymentFields.authStatus = "PENDING_MUST";
    }
  } else {
    // If the reservation is already paid, and the total price has changed, update the paymentTotal
    if (
      reservationData?.paymentData?.paymentTotal &&
      reservationData.paymentData.paymentTotal !== totalPriceCents
    ) {
      const oldTotal = reservationData.paymentData.paymentTotal;
      const oldTodo = reservationData.paymentData.paymentTodo;
      const difference = oldTotal - totalPriceCents;
      paymentFields.paymentData = reservationData.paymentData;
      paymentFields.paymentData.paymentTotal = totalPriceCents;
      let newTodo = oldTodo - difference;
      if (newTodo < 0) newTodo = 0;
      paymentFields.paymentData.paymentTodo = newTodo;
    }

    /*     if (values.paymentMethod === "none") {
      if (reservationData?.paymentStatus) {
        // TODO: Make sure this works in all scenarios, and maybe implement a check on backend
        paymentFields.paymentStatus = null;
      } else {
        paymentFields.paymentStatus = undefined;
      }
    }
    if (values.paymentMethod === "PENDING_INVOICE") {
      paymentFields.paymentStatus = "PENDING_INVOICE";
    }
    if (values.paymentMethod === "COMPLETED_INVOICE") {
      paymentFields.paymentStatus = "COMPLETED_INVOICE";
    }
    if (values.paymentMethod === "COMPLETED_MANUAL") {
      paymentFields.paymentStatus = "COMPLETED_MANUAL";
    } */
  }
  return paymentFields;
};

export const getReservationChannel = (reservationData, userRole) => {
  // set channel for new reservations made from dealer app
  if (!reservationData?.id) {
    return userRole === "ORGANIZATION_ADMIN" ? "DEALER_ORGANIZATION" : "DEALER";
  } else {
    return reservationData?.channel;
  }
};

export const getHanselData = (values, vehicleData) => {
  if (values?.type !== "HANSEL") return undefined;

  const emissionsFromVehicleData = vehicleData
    ?.find((v) => v.id === values?.reservationVehicles[0]?.id)
    ?.additionalFields.find((field) => field.key === "emission")?.value;

  const vehicleMileage =
    values?.reservationVehicles[0]?.endMileage -
    values?.reservationVehicles[0]?.startMileage;

  const mileage = isNaN(vehicleMileage) ? undefined : vehicleMileage;
  const duration = getReservationDuration(
    values?.startTime,
    values?.returnTime
  );
  const defaultHanselData = {
    contractNro: "6004",
  };
  const hanselData = {
    contractNro:
      values?.hanselData?.contractNro ?? defaultHanselData.contractNro,
    customerInvoiceId: values?.hanselData?.customerInvoiceId,
    customerInvoiceDate: values?.hanselData?.customerInvoiceDate,
    hanselAgent: values?.hanselData?.hanselAgent,
    hanselClass: values?.hanselData?.hanselClass,
    customerId: values?.hanselData?.customerId,
    subContractorNumber: values?.hanselData?.subContractorNumber,
    subContractorName: values?.hanselData?.subContractorName,
    suplierProductId: values?.hanselData?.suplierProductId,
    suplierProductName: values?.hanselData?.suplierProductName,
    quantity: values?.hanselData?.quantity,
    price: values?.hanselData?.price,
    totalPrice: values?.hanselData?.totalPrice,
    priceNoVAT: values?.hanselData?.priceNoVAT,
    additionalInfo1: mileage,
    additionalInfo2:
      values?.hanselData?.additionalInfo2 ?? emissionsFromVehicleData,
    additionalInfo3: duration,
    desc: values?.hanselData?.desc,
  };
  return hanselData;
};

export const getPaymentStatus = (values) => {
  if (values?.sendStatusToCustomer) {
    return "PENDING";
  }

  if (["COMPLETED", "COMPLETED_DEPOSIT", "PENDING_FULL", "PENDING"].includes(
      values?.paymentStatus
    )
) {
    return undefined;
  }
  if (values?.paymentStatus === "" && !values.id) {
    // When creating Reservation paymentStatus cannot be null
    return undefined
  }
  if (values?.paymentStatus === "") {
    return null
  }
  return values?.paymentStatus;
};

export const getCustomerCompanyData = (values) => {
  // clear company data if not a company customer
  if (!values?.isCompany) {
    return {
      [reservationFields.customerCompanyId]: values?.customerCompanyId && null,
      [reservationFields.companyName]: values.companyName && null,
      [reservationFields.companyBusinessId]: values.companyBusinessId && null,
      [reservationFields.companyCity]: values.companyCity && null,
      [reservationFields.companyPostalCode]: values.companyPostalCode && null,
    };
  }

  return {
    [reservationFields.customerCompanyId]: values.customerCompanyId,
    [reservationFields.companyName]: values.companyName,
    [reservationFields.companyBusinessId]: values.companyBusinessId,
    [reservationFields.companyCity]: values.companyCity,
    [reservationFields.companyPostalCode]: values.companyPostalCode,
  };
};
