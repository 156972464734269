import { Button, Grid, Menu } from "@mui/material";
import { useFormikContext } from "formik";
import { useContext, useState } from "react";
import { useGetExternalUserOffers } from "../../../../../hooks/useGetExternalUserOffers";
import ServiceOfferMenuItem from "./ServiceOfferMenuItem";
import { translatedFields } from "../../../formik/fields";
import { ReservationContext } from "../../../contextProviders/ReservationContext";

export default function SelectAdditionalServices() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { selectedLanguage } = useContext(ReservationContext);
  const { values } = useFormikContext();
  const { additionalServiceOffers } = useGetExternalUserOffers(values);
  const services = additionalServiceOffers.filter((offer) => {
    const isSelected = values.additionalServices.some(
      (service) => service.key === offer.key
    );
    if (isSelected) {
      return false;
    }
    return true;
  });

  return (
    <>
      <Button
        variant="contained"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{ textTransform: "none", borderRadius: "20px" }}
        fullWidth
      >
        {translatedFields[selectedLanguage]?.selectAdditionalServices}
      </Button>
      {services?.length > 0 && (
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          slotProps={{
            paper: { sx: { minWidth: { lg: "500px", xs: "90vw" } } },
          }}
        >
          {services.map((offer) => (
            <ServiceOfferMenuItem
              key={offer.key}
              offer={offer}
              setAnchorEl={setAnchorEl}
            />
          ))}
        </Menu>
      )}
    </>
  );
}
