import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FormInputField } from "../../../components/ReservationForm/utils/FormInputField";
import { reservationFields, translatedFields } from "../../../formik/fields";
import { EFormSteps } from "..";
import { motion } from "framer-motion";
import PhoneNumberField from "../../../components/PhoneNumberField";
import { useContext } from "react";
import { ReservationContext } from "../../../contextProviders/ReservationContext";
import OrderSummary from "./OrderSummary";
import { useFormikContext } from "formik";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "../../../../../atoms/reservationDataAtom";
import { useValidateForm } from "../../../formik/hooks/useValidateForm";
import { useSearchCustomerCompanyByExternalUser } from "../../../hooks/useSearchCustomerCompanyByExternalUser";
import ScheduleInfo from "./ScheduleInfo";

export default function CustomerForm(props) {
  const { setActiveStep } = props;
  const { selectedLanguage } = useContext(ReservationContext);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <motion.div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "100%",
      }}
      initial={{ opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        x: { type: "spring", stiffness: 200, damping: 40 },
      }}
    >
      <ScheduleInfo />

      <Grid
        container
        item
        spacing={2}
        sx={{ height: "100%" }}
        direction={"row"}
      >
        <Grid
          item
          xs={12}
          sm={8}
          container
          direction={"row"}
          sx={{
            padding: "20px",
          }}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          spacing={3}
        >
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
              <Typography sx={{ color: "grey" }}>
                {translatedFields[selectedLanguage].customerInfo}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormInputField
                name={reservationFields.name}
                label={translatedFields[selectedLanguage].name}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputField
                name={reservationFields.email}
                label={translatedFields[selectedLanguage].email}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <PhoneNumberField
                fieldName="phone"
                selectedLanguage={selectedLanguage}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
              <Typography sx={{ color: "grey" }}>
                {translatedFields[selectedLanguage].companyInfo}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CompanyField />
            </Grid>
            <Grid item xs={12}>
              <FormInputField
                name={reservationFields.companyBillingRef}
                label={translatedFields[selectedLanguage].companyBillingRef}
                required
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputField
                name={reservationFields.companyEBillingOperator}
                label={
                  translatedFields[selectedLanguage].companyEBillingOperator
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputField
                name={reservationFields.companyEBillingAddress}
                label={
                  translatedFields[selectedLanguage].companyEBillingAddress
                }
                size="small"
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FormInputField
              label={translatedFields[selectedLanguage].internalNotes}
              name={reservationFields.internalNotes}
              rows={2}
            />
          </Grid>
        </Grid>
        {!mobileView && (
          <Grid item sm={4} xs={12} sx={{ minHeight: "100%" }}>
            <OrderSummary />
          </Grid>
        )}
      </Grid>
      <Stack
        direction={"row"}
        width={"100%"}
        justifyContent={"space-between"}
        height={"100%"}
        alignItems={"flex-end"}
      >
        <Box>
          <Button onClick={() => setActiveStep(EFormSteps.selectProduct)}>
            {translatedFields[selectedLanguage].back}
          </Button>
        </Box>
        <Box>
          <SubmitForm />
        </Box>
      </Stack>
    </motion.div>
  );
}

function SubmitForm() {
  const { selectedLanguage } = useContext(ReservationContext);
  const { validate } = useValidateForm();
  const { submitForm, isSubmitting } = useFormikContext();
  const setEditReservationData = useSetAtom(reservationDataAtom);
  return (
    <Button
      type="submit"
      variant="contained"
      color="success"
      disableElevation
      disabled={isSubmitting}
      onClick={async () => {
        const isValid = await validate();
        if (!isValid) return;
        try {
          const result = await submitForm();
          if (result?.success) {
            setEditReservationData(null);
          } else {
            // TODO: handle error
          }
        } catch (error) {
          console.error(error);
        }
      }}
    >
      {translatedFields[selectedLanguage].confirmReservation}
    </Button>
  );
}

function CompanyField() {
  const options = useSearchCustomerCompanyByExternalUser();
  const { selectedLanguage } = useContext(ReservationContext);
  const { setFieldValue, values } = useFormikContext();

  return (
    <FormControl fullWidth>
      <InputLabel id="select-customer-company-label">
        {translatedFields[selectedLanguage].companyName}
      </InputLabel>

      <Select
        fullWidth
        size="small"
        labelId="select-customer-company-label"
        id="select-customer-company"
        placeholder={translatedFields[selectedLanguage].companyName}
        label={translatedFields[selectedLanguage].companyName}
        onChange={(event) => {
          setFieldValue("companyName", event.target.value.companyName);
          setFieldValue("customerCompanyId", event.target.value.id);
          setFieldValue(
            "companyBillingAddress",
            event.target.value.companyBillingAddress
          );
          setFieldValue("companyCity", event.target.value.companyCity);
          setFieldValue(
            "companyPostalCode",
            event.target.value.companyPostalCode
          );

          setFieldValue(
            "companyBillingRef",
            event.target.value.companyBillingRef ?? null
          );
        }}
        value={
          options?.find((option) => option.id === values?.customerCompanyId) ??
          ""
        }
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option}>
            <ListItemText
              disableTypography
              primary={
                <Typography sx={{ fontSize: "1.1rem" }}>
                  {option.companyName}
                </Typography>
              }
              secondary={
                <Stack>
                  <CompanyAddressInformation company={option} />
                  <Typography sx={secondaryTypographyStyles}>
                    Viite:{option?.companyBillingRef ?? "Ei viitettä"}
                  </Typography>
                </Stack>
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function CompanyAddressInformation({ company }) {
  if (!company?.companyBillingAddress || !company?.companyCity) return null;
  return (
    <>
      <Typography sx={secondaryTypographyStyles}>
        {company?.companyBillingAddress}
      </Typography>
      <Typography
        sx={secondaryTypographyStyles}
      >{`${company?.companyPostalCode},${company?.companyCity}`}</Typography>
    </>
  );
}

const secondaryTypographyStyles = {
  fontSize: "0.9rem",
  color: "grey",
};
