import { useState } from "react";

import ReservationForm from "./components/ReservationForm";
import CustomerForm from "./components/CustomerForm";
import { AnimatePresence } from "framer-motion";
import ReservationDetails from "./components/ReservationDetails/";
import DialogHeader from "./components/DialogHeader";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../../../../atoms/reservationDataAtom";
import ReservationOverview from "./ReservationOverview";
import { DialogFooter } from "./components/DialogFooter";

export const EFormSteps = {
  selectProduct: "selectProduct",
  customerForm: "customerForm",
};
const ESteps = {
  selectProduct: ReservationDetails,
  customerForm: CustomerForm,
};
export default function ReservationFormExternalUsers(props) {
  const [activeStep, setActiveStep] = useState(EFormSteps.selectProduct);
  const ActiveStep = ESteps[activeStep];
  const reservationData = useAtomValue(reservationDataAtom);
  if (typeof reservationData === "object") {
    return (
      <ReservationForm>
        <DialogHeader activeStep={activeStep} />
        <ReservationOverview />
        <DialogFooter />
      </ReservationForm>
    );
  } else {
    return (
      <ReservationForm>
        <DialogHeader activeStep={activeStep} />
        <AnimatePresence initial={false}>
          <ActiveStep setActiveStep={setActiveStep} />
        </AnimatePresence>
      </ReservationForm>
    );
  }
}
