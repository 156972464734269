import { AutoCompleteComponent } from "../../../components/AutoCompleteComponent";
import { useContext, useMemo, useState } from "react";
import { AppContext } from "../../../../../AppContext";
import { findItemById } from "../../../utils/findItemById";
import { useFormikContext } from "formik";
import { ReservationContext } from "../../../contextProviders/ReservationContext";
import { translatedFields } from "../../../formik/fields";

export default function ExternalUserSelectStartLocation(props) {
  const { setStartLocationOrgBusinessId, serviceProvider } = props;
  const { user } = useContext(AppContext);
  const { selectedLanguage } = useContext(ReservationContext);
  const { setFieldTouched, setFieldValue, values, errors, touched, setValues } =
    useFormikContext();
  const [inputValue, setInputValue] = useState("");

  const availableStartLocations = useMemo(() => {
    let availableLocations = [];
    user?.externalBusiness?.availableCompanyIds.forEach((locationId) => {
      const locationData = findItemById(user, locationId);

      if (locationData && locationData?.orgBusinessId === serviceProvider?.id) {
        availableLocations.push(locationData);
      }
    });

    return availableLocations;
  }, [user, serviceProvider]);

  const handleSelectStartLocation = async (e, newValue) => {
    const companyId = () => {
      // pickup locations have companyId, companies have their own id
      if (newValue?.companyId) {
        return newValue?.companyId;
      } else if (newValue?.id) {
        return newValue?.id;
      }
      // handle user input location company id
      return availableStartLocations[0]?.id;
    };
    /* setQueryResource((prevState) => ({
extBusinessId: user?.externalBusinessId,
orgBusinessId: newValue?.orgBusinessId,

}));*/

    setValues({
      ...values,
      startLocation: newValue?.name,
      startLocationID: companyId(),
      companyId: companyId(),
      orgBusinessId: newValue?.orgBusinessId,
    });

    setStartLocationOrgBusinessId(newValue?.orgBusinessId);
  };

  return (
    <AutoCompleteComponent
      defaultOptions={availableStartLocations}
      label={translatedFields[selectedLanguage]?.startLocation}
      type={"location"}
      size={"small"}
      inputValue={inputValue}
      onInputChange={(e, newValue) => {
        setInputValue(newValue);
        setFieldValue("startLocation", newValue);
        setFieldValue(
          "startLocationID",
          serviceProvider?.companies?.items[0]?.id
        );
      }}
      value={values?.startLocation}
      onChange={handleSelectStartLocation}
      freeSolo={true}
      error={!!errors?.startLocation && touched.startLocation}
      errorHelperText={touched.startLocation && errors?.startLocation}
      disabled={!serviceProvider}
      name="startLocation"
      onBlur={() => {
        setFieldTouched("startLocation", true, true);
      }}
    />
  );
}
